import React from "react";

export default function Assignment() {
  return (
    <div>
      <div className="main-question mt-5">
        Little interest or pleasure in doing things?
      </div>
      <div className="answer-option">Not at all</div>
      <div className="main-question">Feeling down, depressed, or hopeless?</div>
      <div className="answer-option">Not at all</div>
      <div className="main-question">
        Trouble falling or staying asleep, or sleeping too much?
      </div>
      <div className="answer-option">Several days</div>
      <div className="text-center">
        <button className="assign-button mb-3">Not at all</button>
        <button className="assign-button mb-3">Several days</button>
        <button className="assign-button mb-3">More than half the days</button>
        <button className="assign-button">Nearly every day</button>
      </div>
    </div>
  );
}
