import React, { useEffect, useMemo } from "react";
import ReactWebChat, {
  createDirectLine,
  createStyleSet,
  hooks,
} from "botframework-webchat";
import "./WebChat.css";
import "./styles/assignment.css";

const WebChat = ({
  className,
  onFetchToken,
  store,
  token,
  adtoken,
  userid,
  username,
}) => {
  const directLine = useMemo(() => createDirectLine({ token }), [token]);

  console.log({ directLine });
  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: "Transparent",
        botAvatarInitials: "BA",
        userAvatarInitials: "UA",
      }),
    []
  );

  const activityMiddleware =
    () =>
    (next) =>
    (...args) => {
      const [card] = args;
      if (card.activity) {
        console.log({ card });
        if (true) {
          return (
            <div key={card.activity.id} className="custom-class">
              {next(card)}
            </div>
          );
        }
      }
    };

  useEffect(() => {
    onFetchToken();
  }, [onFetchToken]);

  console.log({ store });

  return token ? (
      <div>
        <div className="top-header">
          <div className="page-header-container">
            <div>
              <i className="fa-solid fa-arrow-left ft-2"></i>
              <span className="page-title-chat page-title ">Chat</span>
            </div>
            <div className="d-flex align-items-center">
              <i className="fa-solid fa-arrow-up-a-z caps mx-2"></i>
              <i className="fa-solid fa-arrow-down-a-z caps mx-2"></i>
              <i className="fa-solid fa-gear gear"></i>
            </div>
          </div>
          <div className="toggle-button-effect">
            <button
              id="left-togl-btn"
              type="button"
              className="btn btn-primary btn-purple mt-3 h-3 toggle-active-btn-left"
            >
              {" "}
              Chat{" "}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-purple mt-3 h-3 toggle-active-btn-right"
            >
              {" "}
              Toolbox{" "}
            </button>
          </div>
        </div>
        <ReactWebChat
          className={`${className || ""} web-chat`}
          directLine={directLine}
          store={store}
          userID={userid}
          username={username}
          channelData={adtoken}
          styleSet={styleSet}
        />
      </div>
  ) : (
    <div className={`${className || ""} connect-spinner`}>
      <div className="content">
        <div className="icon">
          <span className="ms-Icon ms-Icon--Robot" />
        </div>
        <p>Please wait while we are connecting.</p>
      </div>
    </div>
  );
};

export default WebChat;
