import classNames from "classnames";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { createStore, createStyleSet } from "botframework-webchat";
import simpleUpdateIn from "simple-update-in";

import WebChat from "./WebChat";
import "./fabric-icons-inline.css";
import "./MinimizableWebChat.css";

const MinimizableWebChat = ({ adtoken, userid, username }) => {
  const store = useMemo(
    () =>
      createStore({}, ({ dispatch }) => (next) => (action) => {
        const element = document.querySelector(
          ".css-eycyw2 > .main, [data-css-eycyw2] > .main"
        );
        if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
          dispatch({
            type: "WEB_CHAT/SEND_EVENT",
            payload: {
              name: "webchat/join",
              value: {
                language: window.navigator.language,
                name: "demo",
              },
            },
          });
        } else if (action.type === "DIRECT_LINE/POST_ACTIVITY") {
          action = simpleUpdateIn(
            action,
            ["payload", "activity", "channelData", "userToken"],
            () => adtoken
          );
        } else if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
          if (action?.payload?.activity?.name === "unhidesendbox") {
            if (element) {
              element.style.display = "flex";
            }
          }
          else {
            element.style.display = "none";
          }
        }
        return next(action);
      }),
    []
  );

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: "Transparent",
      }),
    []
  );

  useEffect(() => {
    console.log("testing .........");
    setLoaded(true);
    setMinimized(false);
    setNewMessage(false);
    setClickedBotIcon(true);
  }, []);

  const [loaded, setLoaded] = useState(false);
  const [minimized, setMinimized] = useState(true);
  const [newMessage, setNewMessage] = useState(false);
  const [clickedBotIcon, setClickedBotIcon] = useState(false);
  const [side, setSide] = useState("right");
  const [token, setToken] = useState();

  const handleFetchToken = useCallback(async () => {
    if (!token) {
      try {
        const SERVER_URL = process.env.REACT_APP_HOSTNAME;
        console.log("minimizable Config hostname is :" + SERVER_URL);
        const res = await fetch(
          "https://" + SERVER_URL + "/Api/DirectLineAuth/TokenDirectLine",
          { method: "GET" }
        );

        const { token } = await res.json();

        setToken(token);
      } catch (e) {
        console.log("Error " + e);
      }
    }
  }, [setToken, token]);

  const handleMaximizeButtonClick = useCallback(async () => {
    setLoaded(true);
    setMinimized(false);
    setNewMessage(false);
    setClickedBotIcon(true);
  }, [setMinimized, setNewMessage, setClickedBotIcon]);

  const handleMinimizeButtonClick = useCallback(() => {
    setMinimized(true);
    setNewMessage(false);
  }, [setMinimized, setNewMessage]);

  const handleCloseButtonClick = useCallback(() => {
    window.location.reload(false);
  });
  const handleSwitchButtonClick = useCallback(() => {
    setSide(side === "left" ? "right" : "left");
  }, [setSide, side]);

  return (
    <WebChat
      className="react-web-chat"
      onFetchToken={handleFetchToken}
      store={store}
      styleSet={styleSet}
      token={token}
      adtoken={adtoken}
      userid={userid}
      username={username}
    />
  );
};

export default MinimizableWebChat;
